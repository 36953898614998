<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Vue from 'vue'
import VWave from 'v-wave'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import vSelect from 'vue-select'
import LayoutBackoffice from './backend/LayoutBackoffice.vue'
import LayoutBackofficeEmpty from './backend/LayoutBackofficeEmpty.vue'
import VueApexCharts from 'vue-apexcharts'
import $ from 'jquery'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'

import VueMask from 'v-mask'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar'
// import 'v-calendar/style.css';

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: h => h('i', { class: 'icon-arrow-down22' })
  },
  Deselect: {
    render: h => h('i', { class: 'icon-cross' })
  }
})
vSelect.props.clearable = false

require('@/assets/scss/style.scss')
require('@/assets/css/custom_bo.css?v=0.1')
require('@/assets/css/responsive_bo.css?v=0.1')

Vue.use(VueMask)
Vue.use(PerfectScrollbar)
Vue.use(VWave)
Vue.component('v-select', vSelect)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.component('vue-bootstrap-autocomplete', VueBootstrapAutocomplete)
Vue.use(setupCalendar, {})
Vue.component('VCalendar', Calendar)
Vue.component('VDatePicker', DatePicker)

export default {
  components: {
    LayoutBackoffice, LayoutBackofficeEmpty,
  },
  mounted() {
    setTimeout(()=>{
      $("input").attr("autocomplete","off")
    },1000)

    $('body').on('keydown', "input[type='text']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })

    $('body').on('keydown', "input[type='tel']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "input[type='password']", function (e) {
        if (e.which === 32 && e.target.selectionStart === 0) {
            return false
        }
    })
    
    $('body').on('keydown', "input[type='email']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "input[type='number']", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
    
    $('body').on('keydown', "textarea", function(e) {
      if (e.which === 32 &&  e.target.selectionStart === 0) {
        return false
      }  
    })
  },
  computed: {
    layout() {
      return this.$route.meta.layout === 'empty' ? 'layout-backoffice-empty' : 'layout-backoffice'
    }
  }  
}
</script>
