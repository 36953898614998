<template>
  <b-navbar type="light" toggleable="xl" fixed="top">
    <!-- Header with logos -->
    <div class="navbar-header navbar-light d-none d-xl-flex align-items-xl-center">
      

      <div class="navbar-brand navbar-brand-md">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('global_assets/images/logo-rsh-wide.png')" alt="RSH Logo">
				</router-link>
			</div>
			
			<div class="navbar-brand navbar-brand-xs">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="height:auto; width: 2rem;" alt="RSH Logo">
				</router-link>
			</div>
    </div>
    
      
    <div style="margin-top: 22px;width:20px;cursor:pointer;" v-if="user.levelId == uPerawat || user.levelId == uDokter || user.levelId == uDokterFisio || user.levelId == uAdmisi" @click="getUnit(true)">
      <i v-b-tooltip.hover="isHaveUnit ? 'Ubah Unit' : 'Pilih Unit'" class="icon-cog"></i>
      <span v-if="!isHaveUnit" style="position: absolute;" class="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
    </div>
    <!-- /header with logos -->

    <!-- Mobile controls -->
    <div class="d-flex flex-1 d-xl-none">
      <button class="navbar-toggler sidebar-mobile-main-toggle pr-3" type="button" @click="toggleSidebarMobile">
        <i class="icon-paragraph-justify3"></i>
      </button>
			<div class="navbar-brand mr-auto">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('global_assets/images/logo-rsh-wide.png')" alt="RSH Logo">
				</router-link>
			</div>	
			<button class="navbar-toggler" type="button" v-b-toggle.navbar-mobile>
				<i class="icon-tree5"></i>
			</button>
    </div>
    <!-- /mobile controls -->


    <!-- Navbar content -->
    <b-collapse is-nav id="navbar-mobile">
      <b-navbar-nav class="align-items-center">
        <b-nav-item
          link-classes="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-xl-block"
          @click="toggleSidebarDesktop"
        >
          <i class="icon-paragraph-justify3"></i>
        </b-nav-item>
      </b-navbar-nav>
      <div class="nav-link d-flex mr-md-auto"> 
        <span v-if="isOnline" class="badge bg-success-400 badge-pill ml-xl-3">online</span> 
        <span v-else class="badge bg-danger-400 badge-pill ml-xl-3">offline</span> 
        
        <span class="ml-2 d-inline-block mb-0"> {{dateTime || getDate}} </span>
        <span class="ml-auto ml-xl-5 d-inline-block mb-0"> Application Ver. : {{appVersion}}</span>
        <span v-if="localIP" class="ml-auto ml-xl-2 d-inline-block mb-0"> Ip. : {{localIP}}</span>
      </div>
      
      <b-navbar-nav class="align-items-lg-center">
        <b-nav-item-dropdown right toggle-class="navbar-nav-link d-flex align-items-center" menu-class="wmin-md-350 py-0" no-caret>
          <template #button-content>
            <i class="icon-bell3"></i>
            <span class="d-xl-none ml-2">Messages</span>
            <span  v-if="(notificationList.data||[]).findIndex(x=>x.an_status == 'D') !== -1" class="badge badge-mark border-pink-400 ml-auto ml-xl-0"></span>
          </template>
          <div class="dropdown-content-header">
            <span class="font-weight-semibold">Messages</span>
          </div>
          <div class="dropdown-content-body dropdown-scrollable">
            <ul class="media-list" v-if="(notificationList.data||[]).length">
              <li class="media" v-for="(v,k) in (notificationList.data||[])" :key="k">
                <template>
                  <div class="mr-3 position-relative">
                    <img :src="uploader(v.bu_avatar)" width="36" height="36" class="rounded-circle" alt="">
                  </div>
                  <div class="media-body">
                    <div class="media-title">
                      <template v-if="v.an_more_opt == 'kontrol'">
                        <router-link :to="{ name: 'RoRencanaKontrol', params: { pageSlug: v.arrk_id}}" class="font-weight-semibold">
                        {{v.bu_full_name||""}}
                        </router-link>
                        <span class="text-muted float-right font-size-sm">{{momentDate(v.an_created_date)}}</span>
                      </template>
                      <template v-else>
                        
                        <router-link :to="{ name: 'RekamMedis', params: { pageSlug: v.an_pasien_id }, query: {regId: v.an_ar_id} }" v-if="v.an_pasien_id && v.an_ar_id" class="font-weight-semibold">
                         {{v.bu_full_name||""}}
                        </router-link>

                        <router-link :to="{ name: 'RoReservasi', params: { pageSlug: v.an_res_id}, query: {isDetail: true} }" v-if="v.an_res_id" class="font-weight-semibold">
                        {{v.bu_full_name||""}}
                        </router-link>
                        <span class="text-muted float-right font-size-sm">{{momentDate(v.an_created_date)}}</span>
                      </template>
                    </div>
                    <span class="text-muted" v-html="v.an_message"></span>
                    <span class="text-muted">
                        <span :class="v.an_status == 'D'?'text-muted float-right font-size-sm mt-3':'text-info float-right font-size-sm mt-3'">{{v.an_status == 'D' ? 'Belum Dibaca' : 'Sudah Dibaca'}}</span>
                    </span>
                  </div>
                </template>
							</li>
            </ul>
            <span v-else>No Messages Found</span>
          </div>
          <div class="dropdown-content-footer bg-light">
            <router-link :to="{name: 'Notification'}" v-if="(notificationList.data||[]).length"
              class="text-grey mr-auto" >All messages</router-link>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right toggle-class="navbar-nav-link d-flex align-items-center">
          <template #button-content>
            <b-img
              :src="uploader(user.avatar)"
              class="mr-2"
              rounded="circle"
              height="34"
              alt=""
            />
            <span>{{ user.fullName }}</span>
          </template>
          <b-dropdown-item :to="{name: 'AccountSetting'}">
            <i class="icon-cog5"></i> Account settings
          </b-dropdown-item>
          <b-dropdown-item @click="doLogout">
            <i class="icon-switch2"></i> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    
    <validation-observer ref="VFormSetUnit">
    <b-modal v-model="modalOpenUnit" :title="'Unit Tujuan'" size="sm" @ok.prevent="submitChangeUnit">
        <div class="row">
            <div class="form-group col-md-12">
                <label> Pilih Unit Tujuan <small class="txt_mandatory">*</small></label>
                
                <v-select placeholder="Pilih Unit Tujuan" v-model="dispData.arud_unit"
                  :options="mUnit" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>

                <VValidate 
                    name="Unit Tujuan" 
                    v-model="dispData.arud_unit" 
                    :rules="{required: 1}"
                />
            </div>
        </div>
    </b-modal>
    </validation-observer>
  </b-navbar>
  
  
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import $ from 'jquery'

export default {
  extends: GlobalVue,
  data() {
    return {
      notificationList: [],
      dateTime : null,
      isOnline : true,
      dispData : {},
      isHaveUnit : true,
      modalOpenUnit : false,
      mUnit : [],
      localIP: null,
      appVersion : '2.2.7',
      isSesiHabis: null
    }
  },
  mounted() {
    this.isSesiHabis = localStorage.getItem("isSesiHabis")

    if (this.isSesiHabis == 'Y') {
      this.showPopupSesiHabis()
    }

    setInterval(()=>{
      this.dateTime = moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
    },1000)
    
    if(process.env.VUE_APP_MODE != 'local'){
      setInterval(()=>{
        this.checkOnlineStatus()
      },5000)
    }
    this.getNotification()
    this.getUnit()
    this.fetchLocalIpAddress()
  },
  watch:{
    $route(to, from) {
      if (to.name !== from.name) {
        this.getNotification()
      }
      
      if(this.user){
        if(this.isList && this.user.levelId == this.uPerawatUGD){
          Gen.apiRest(
            "/do/"+'UGDTriase',
            {data:{type:'remove-on-triase',id_user: this.user.id}}, 
            "POST"
          )
          Gen.apiRest(
            "/do/"+'UGDFormPerawat',
            {data:{type:'remove-on-perawat',id_user: this.user.id}}, 
            "POST"
          )
        }
      } 
    }
  },
  computed: {
    getDate(){
      return moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
    },
    
    nodeUrl(){
      return process.env.VUE_APP_SOCKET_URL
    }
  },
  methods: {
    async fetchLocalIpAddress() {
        try {
            const response = await fetch(this.nodeUrl+'/get-ip')
            const data = await response.json()
            this.localIP = data.ip
            console.log(this.localIP)
        } catch (error) {
            console.error('Error fetching local IP address:', error)
        }
    },
    submitChangeUnit(){
      let data = this.dispData
      this.$refs['VFormSetUnit'].validate().then(success=>{
        if(success){
          this.loadingOverlay = true
          data.type = "set-unit-users"
          Gen.apiRest(
            "/do/" + 'RoUsersDisplay', {
              data: data
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            
            this.$socket.emit('refresh_data', {to : this.uPerawat})
            this.$socket.emit('refresh_data', {to : this.uDokter})
            this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            this.$socket.emit('refresh_data', {to : this.uAdmisiUGD})
            this.$socket.emit('refresh_data', {to : this.uAdmisi}) 
            
            this.$socket.emit('refresh_unit', {})
          
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              this.getUnit()
              if (result.value) {
                this.modalOpenUnit = false
              }
            })
          })
        }
      })
    },
    getUnit(force=false){
      if(this.user.levelId == this.uPerawat || this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uAdmisi){
          
        Gen.apiRest(
          "/get/"+"RoUsersDisplay"
        ).then(res=>{
          this.isHaveUnit = res.data.isHave
          if(!res.data.isHave || force){
            if(!this.isPoliAdjRanap || force){
              this.modalOpenUnit = true
            }
            this.mUnit = res.data.mUnit
            this.dispData = res.data.dispData
          }
        })
      }
    },
    getNotification(){
      Gen.apiRest(
        "/get/"+"Notification",
        {
          params: {
            id : parseInt(this.user.id),
            levelId : parseInt(this.user.levelId),
          }
        }
      ).then(res=>{
        this.notificationList = res.data.data
        if(this.user.levelId == this.uFarmasi || this.user.levelId == 1){
          $("#obat-jadi-notif").remove()
          $("#obat-racikan-notif").remove()

          if(res.data.farmasiUGDObatJadi){
            $("#UGDFarmasi a").append(`<span title="Obat Jadi" id="obat-jadi-notif" class="badge badge-danger ml-auto">
            ${res.data.farmasiUGDObatJadi}
            </span>`)
          }
          if(res.data.farmasiUGDObatRacikan){
            $("#UGDFarmasi a").append(`<span title="Obat Racikan" id="obat-racikan-notif" class="badge badge-info ml-auto">
            ${res.data.farmasiUGDObatRacikan}
            </span>`)
          }
   
          $("#obat-jadi-ranap-notif").remove()
          $("#obat-racikan-ranap-notif").remove()
          if(res.data.farmasiRanapObatJadi){
            $("#RanapFarmasi a").append(`<span title="Obat Jadi" id="obat-jadi-ranap-notif" class="badge badge-danger ml-auto">
            ${res.data.farmasiRanapObatJadi}
            </span>`)
          }
          if(res.data.farmasiRanapObatRacikan){
            $("#RanapFarmasi a").append(`<span title="Obat Racikan" id="obat-racikan-ranap-notif" class="badge badge-info ml-auto">
            ${res.data.farmasiRanapObatRacikan}
            </span>`)
          }

          $("#obat-pulang-jadi-ranap-notif").remove()
          $("#obat-pulang-racikan-ranap-notif").remove()
          if(res.data.farmasiResepPulangJadi){
            $("#RanapFarmasiResepPulang a").append(`<span title="Obat Jadi" id="obat-pulang-jadi-ranap-notif" class="badge badge-danger ml-auto">
            ${res.data.farmasiResepPulangJadi}
            </span>`)
          }
          if(res.data.farmasiResepPulangRacikan){
            $("#RanapFarmasiResepPulang a").append(`<span title="Obat Racikan" id="obat-pulang-racikan-ranap-notif" class="badge badge-info ml-auto">
            ${res.data.farmasiResepPulangRacikan}
            </span>`)
          }
        }
        
        if(this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uDokterUGD || this.user.levelId == 1){
           // pasien pulang yah
          $("#pasien-pulang-notif").remove()
          if(res.data.notifPasienSelesai){
            $("#RanapPasienSelesai a").append(`<span title="Jumlah Pasien Pulang" id="pasien-pulang-notif" class="badge badge-info ml-auto">
            ${res.data.notifPasienSelesai}
            </span>`)
          }
        }

        if(this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == 1){
          $("#laporan-efektivitas-notif").remove()
          if(res.data.notifLaporanDokter){
            $("#RoLaporanEfektivitasJadwal a").append(`<span title="Laporan Dokter Terlambat" id="laporan-efektivitas-notif" class="badge badge-danger ml-auto">
            ${res.data.notifLaporanDokter}
            </span>`)
          }
        }

      })
    },
    momentDate(dates){
      let formatted = moment(dates).format("YYYYMMDDHHmm")
      let newDates = moment(formatted, "YYYYMMDDHHmm").fromNow()
      return newDates
    },
    toggleSidebarDesktop() { document.querySelector('body').classList.toggle('sidebar-xs') },
    toggleSidebarMobile() {
      document.querySelector('body').classList.toggle('sidebar-mobile-main')
      document.querySelector('.sidebar.sidebar-main').classList.remove('sidebar-fullscreen')
    },
    logout() {
      localStorage.setItem('reasonLogout', 'manual')
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'Users', {
        data: {
          type: 'remove-token'
        }
      }, 'POST').then(()=>{
        this.loadingOverlay = false
        this.$store.commit('unsetAuth')
        this.$router.push({ name: 'BoAuth' }).catch(()=>{})
      })
    },
    getTime(){
      this.dateTime = moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
    },
    async checkOnlineStatus (){
      try {
        const online = await fetch("https://jsonplaceholder.typicode.com/posts")
        this.isOnline = true
      } catch (err) {
        this.isOnline = false
        return false // definitely offline
      }
    },
    showPopupSesiHabis() {
      this.$swal({
        icon: 'warning',
        html: '<h2><b>Tidak dapat melanjutkan.</b></h2>Akun Anda terdeteksi telah login di komputer lain.',
        confirmButtonText: 'Login Ulang',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
          localStorage.setItem('reasonLogout', 'unauthorized')
          this.$store.commit("logoutUserToken")
          setTimeout(() => {
            window.location.href = this.$router.resolve({ name: "Login" }).href
          }, 100)
        }
      })
    }
  },
  sockets: {
    receive_auth_deleted(data) {
      if(data) {
        const userId = this.user.id
        const userToken = this.userToken
        const selectedToken = data.auth_deleted[userId] || []

        if (selectedToken.indexOf(userToken) > -1) {
          this.isSesiHabis = "Y"
          localStorage.setItem("isSesiHabis", "Y")
          
          this.showPopupSesiHabis()
        }
      }
    }
  }
}
</script>