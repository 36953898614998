<template>
  <b-nav-item
    :to="toNav(item.route.name)"
    :active-class="active?'active':''"
    :id="item.menu"
  >
    <i :class="item.icon" v-if="item.icon" v-b-tooltip.hover.right :title="item.title" />
    <span>{{ item.title }}</span> 
  </b-nav-item>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    active() {
      return this.$route.name == this.item.route.name
    }
  },
  methods: {
    toNav(name){
      if(this.$route.meta.isDetailRanap){
        return {
          name : name,
          params : { pageSlug : this.$route.params.pageSlug } 
        } 
      }else{
        return {name : name}
      }
    }
  },
}
</script>
