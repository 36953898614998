import _ from 'lodash'

const menu = [
  {
    cap: 'PASIEN RAWAT INAP',
    relate: [
      'RanapMonitoring',
      'RanapDPPerawat',
      'RanapETPerawat',
      'RanapCPPT',
      'RanapPasienAsuhanKeperawatan',
      'RanapPasienOperasi',
      'RanapPasienTindakanLaboratorium',
      'RanapPasienTindakanRadiologi',
      'RanapPasienTindakanFisioterapi',
      'RanapPasienTindakanICD9',
      'RanapSerahTerimaPasien',
      'RanapPasienPermintaanResep',
      'RanapPemberianMakan',
      'RanapPasienKajianNyeri',
      'RanapPasienKajianResikoJatuh',
      'RanapPasienKajianRestrain',
      'RanapFormulirLainnyaPerawat'
    ],
    isDetailRanap: true,
  }, {
    title: 'Monitoring',
    route: {
      name: 'RanapMonitoring'
    },
    menu: 'RanapMonitoring',
    icon: 'fas fa-laptop-medical',
    isDetailRanap: true,
  },{
    title: 'Discharge Planning',
    route: {
      name: 'RanapDPPerawat'
    },
    menu: 'RanapDPPerawat',
    icon: 'fas fa-hospital-user',
    isDetailRanap: true,
  }, {
    title: 'Edukasi Terintegrasi',
    route: {
      name: 'RanapETPerawat'
    },
    menu: 'RanapETPerawat',
    icon: 'fas fa-book-medical',
    isDetailRanap: true,
  },{
    title: 'Edukasi Terintegrasi',
    route: {
      name: 'RanapETDokter'
    },
    menu: 'RanapETDokter',
    icon: 'fas fa-book-medical',
    isDetailRanap: true,
  }, {
    title: 'Asuhan Terintegrasi',
    icon: 'fas fa-pager',
    children: [
      {
        title: 'CPPT',
        route: {
          name: 'RanapCPPT'
        },
        menu: 'RanapCPPT',
        isDetailRanap: true
      },
      {
        title: 'Asuhan Keperawatan',
        route: {
          name: 'RanapPasienAsuhanKeperawatan'
        },
        menu: 'RanapPasienAsuhanKeperawatan',
        isDetailRanap: true
      },

      
    ]
  }, {
    title: 'Tindakan',
    icon: 'fas fa-hand-holding-medical',
    children: [
      {
        title: 'Operasi',
        route: {
          name: 'RanapPasienOperasi'
        },
        menu: 'RanapPasienOperasi',
        isDetailRanap: true
      }, 
      {
        title: 'Laboratorium',
        route: {
          name: 'RanapPasienTindakanLaboratorium'
        },
        menu: 'RanapPasienTindakanLaboratorium',
        isDetailRanap: true
      },
      {
        title: 'Radiologi',
        route: {
          name: 'RanapPasienTindakanRadiologi'
        },
        menu: 'RanapPasienTindakanRadiologi',
        isDetailRanap: true
      },
      {
        title: 'Fisioterapi',
        route: {
          name: 'RanapPasienTindakanFisioterapi'
        },
        menu: 'RanapPasienTindakanFisioterapi',
        isDetailRanap: true
      },
      {
        title: 'Tindakan ICD 9',
        route: {
          name: 'RanapPasienTindakanICD9'
        },
        menu: 'RanapPasienTindakanICD9',
        isDetailRanap: true
      },
      
    ]
  }, {
    title: 'Serah Terima Pasien',
    route: {
      name: 'RanapSerahTerimaPasien'
    },
    menu: 'RanapSerahTerimaPasien',
    icon: 'fas fa-arrow-right-arrow-left',
    isDetailRanap: true,
  }, {
    title: 'Permintaan Resep',
    route: {
      name: 'RanapPasienPermintaanResep'
    },
    menu: 'RanapPasienPermintaanResep',
    icon: 'fas fa-prescription-bottle-medical',
    isDetailRanap: true,
  }, {
    title: 'Pemberian Makan',
    route: {
      name: 'RanapPemberianMakan'
    },
    menu: 'RanapPemberianMakan',
    icon: 'fa-solid fa-bowl-food',
    isDetailRanap: true,
  }, {
    title: 'Kajian pasien',
    icon: 'fas fa-file-waveform',
    children: [
      {
        title: 'Kajian Nyeri',
        route: {
          name: 'RanapPasienKajianNyeri'
        },
        menu: 'RanapPasienKajianNyeri',
        isDetailRanap: true
      },
      {
        title: 'Kajian Resiko Jatuh',
        route: {
          name: 'RanapPasienKajianResikoJatuh'
        },
        menu: 'RanapPasienKajianResikoJatuh',
        isDetailRanap: true
      },
      {
        title: 'Kajian Terminal',
        route: {
          name: 'RanapPasienKajianTerminal'
        },
        menu: 'RanapPasienKajianTerminal',
        isDetailRanap: true
      },
      {
        title: 'Kajian TBC',
        route: {
          name: 'RanapPasienKajianTBC'
        },
        menu: 'RanapPasienKajianTBC',
        isDetailRanap: true,
      },
      {
        title: 'Kajian Restrain',
        route: {
          name: 'RanapPasienKajianRestrain'
        },
        menu: 'RanapPasienKajianRestrain',
        isDetailRanap: true
      },
      {
        title: 'Kajian Fibrinolisis',
        route: {
          name: 'RanapPasienKajianStriptokinase'
        },
        menu: 'RanapPasienKajianStriptokinase',
        isDetailRanap: true
      },
    ]
  }, {
    title: 'Formulir Lainnya',
    route: {
      name: 'RanapFormulirLainnyaPerawat'
    },
    menu: 'RanapFormulirLainnyaPerawat',
    icon: 'fas fa-receipt',
    isDetailRanap: true,
  }
  
]

let nav = menu
const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        if((v2.children||[]).length){
          v2.children = _.filter(v2.children, v3 => {
            return menuRole.indexOf(v3.menu) > -1
          })
          if ((v2.children||[]).length) {
            return v2
          }
        }else{
          return menuRole.indexOf(v2.menu) > -1
        }
      })
    }
    return v
  })
}

export default nav