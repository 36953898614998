import _ from 'lodash'

const menu = [
  // NOTIFY!! - New Menu
  {
    cap: 'Poliklinik',
    relate: [
      'Dashboard',
      'RoRujukan',
      'KeselamatanPasien',
      'Hais',
      'LaporanInsiden',
      'Dokter',
      'RoAdmisiAntrean',
      // 'RoPasien',
      'RoPasienNew',
      'RoReservasi',
      'RoJadwalDokter',
      'RoLaporanEfektivitasJadwal',
      'WABlasting',
      'RegistrasiPasien',
      'RoBatalkanTindakanPenunjang',
      'POCTLab',
      'RoPasienTindakanICD9',
    ],
  },
  {
    title: 'Dashboard',
    route: {name: 'Dashboard'},
    menu: 'Dashboard',
    icon: 'icon-home4',
  },
  { 
    title: 'Admisi',
    icon: 'icon-file-text3',
    children: [
      {
        title: 'Antrean',
        route: {
          name: 'RoAdmisiAntrean'
        },
        menu: 'RoAdmisiAntrean',
        icon: 'icon-list',
      },
      // {
      //   title: 'Pasien',
      //   route: {name: 'RoPasien'},
      //   menu: 'RoPasien',
      //   icon: 'icon-users',
      // },
      {
        title: 'Pasien',
        route: {
          name: 'RoPasienNew'
        },
        menu: 'RoPasienNew',
        icon: 'icon-users',
      },
      {
        title: 'Reservasi',
        route: {
          name: 'RoReservasi'
        },
        menu: 'RoReservasi',
        icon: 'icon-bookmarks',
      },
      {
        title: 'Jadwal Dokter',
        route: {
          name: 'RoJadwalDokter'
        },
        menu: 'RoJadwalDokter',
        icon: 'icon-calendar',
      },
      {
        title: 'Pesan Massal',
        route: {
          name: 'WABlasting'
        },
        menu: 'WABlasting',
        icon: 'icon-paperplane',
      },
      {
        title: 'Rujukan',
        route: {
          name: 'RoRujukan'
        },
        menu: 'RoRujukan',
        icon: 'icon-file-text3',
      },
      {
        title: 'Rencana Kontrol',
        route: {
          name: 'RoRencanaKontrol'
        },
        menu: 'RoRencanaKontrol',
        icon: 'icon-file-text3',
      },
      {
        title: 'Registrasi Pasien',
        route: {
          name: 'RegistrasiPasien'
        },
        menu: 'RegistrasiPasien',
        icon: 'icon-list',
      },
    ]
  },
  {
    title: 'Resiko Jatuh',
    route: {name: 'KeselamatanPasien'},
    menu: 'KeselamatanPasien',
    icon: 'icon-shield2',
  },
  {
    title: 'HAIs',
    route: {name: 'Hais'},
    menu: 'Hais',
    icon: 'icon-notification2',
  },
  {
    title: 'Laporan Insiden',
    route: {name: 'LaporanInsiden'},
    menu: 'LaporanInsiden',
    icon: 'icon-file-text3',
  },
  {
    title: 'Dokter',
    route: {name: 'Dokter'},
    menu: 'Dokter',
    icon: 'icon-briefcase',
  },  
  {
    title: 'Rencana Penunjang',
    route: {name: 'RoBatalkanTindakanPenunjang'},
    menu: 'RoBatalkanTindakanPenunjang',
    icon: 'icon-notification2',
  },
  {
    title: 'POCT',
    route: {
      name: 'POCTLab'
    },
    menu: 'POCTLab',
    icon: 'icon-stack-text',
  },
  {
    title: 'Laporan Efektivitas Jadwal',
    route: {
      name: 'RoLaporanEfektivitasJadwal'
    },
    menu: 'RoLaporanEfektivitasJadwal',
    icon: 'icon-list',
  },
  {
    title: 'Pasien Tindakan ICD9',
    route: {
      name: 'RoPasienTindakanICD9'
    },
    menu: 'RoPasienTindakanICD9',
    icon: 'icon-file-text3',
  },

  
  {
    cap: 'UGD',
    relate: [
      'DashboardAdmisiUGD',
      'UGDAdmisiAntrean',
      'UGDReservasi',
      'UGDTriase',
      'UGDAssesmentPerawat',
      'UGDAssesmentDokter',
      'UGDTindakLanjut',
      'UGDET',
      'UGDAssesmenLab',
      'UGDPOCTLab',
      'UGDAssesmenRadiologi',
      'UGDFarmasi',
      'UGDRekonsiliasi',
      'UGDRekamMedis',
      'UGDUPLA',
      'WABlasting',
      'RegistrasiPasien',
      'UGDRujukanMasuk',
      'UGDRujukanKeluar',
      'UGDSuratEmergency',
      'UGDHais',
      'UGDPenerbitanSurat',
      'UGDPasienRanap',
      'UGDCPPTDokterSpesialist'
    ],
  },

  {
    title: 'Dashboard UGD',
    route: {name: 'DashboardAdmisiUGD'},
    menu: 'DashboardAdmisiUGD',
    icon: 'icon-home4',
  },
  {
    title: 'Antrean UGD',
    route: {
      name: 'UGDAdmisiAntrean'
    },
    menu: 'UGDAdmisiAntrean',
    icon: 'icon-list',
  },
  {
    title: 'Pendaftaran UGD',
    route: {
      name: 'UGDReservasi'
    },
    menu: 'UGDReservasi',
    icon: 'icon-bookmarks',
  },
  {
    title: 'Triase UGD',
    route: {
      name: 'UGDTriase'
    },
    menu: 'UGDTriase',
    icon: 'icon-gradient',
  },
  {
    title: 'Kajian Perawat UGD',
    route: {
      name: 'UGDAssesmentPerawat'
    },
    menu: 'UGDAssesmentPerawat',
    icon: 'icon-notification2',
  },
  {
    title: 'Kajian Dokter UGD',
    route: {
      name: 'UGDAssesmentDokter'
    },
    menu: 'UGDAssesmentDokter',
    icon: 'icon-notification2',
  },
  {
    title: 'Tindak Lanjut',
    route: {
      name: 'UGDTindakLanjut'
    },
    menu: 'UGDTindakLanjut',
    icon: 'icon-notification2',
  },
  
  {
    title: 'Edukasi Terintegrasi',
    route: {
      name: 'UGDET'
    },
    menu: 'UGDET',
    icon: 'fas fa-book-medical',
  },
  {
    title: 'UGD Laboratorium',
    route: {
      name: 'UGDAssesmenLab'
    },
    menu: 'UGDAssesmenLab',
    icon: 'icon-home4',
  },
  {
    title: 'POCT',
    route: {
      name: 'UGDPOCTLab'
    },
    menu: 'UGDPOCTLab',
    icon: 'icon-stack-text',
  },
  {
    title: 'UGD Radiologi',
    route: {
      name: 'UGDAssesmenRadiologi'
    },
    menu: 'UGDAssesmenRadiologi',
    icon: 'icon-home4',
  },
  {
    title: 'UGD Farmasi',
    route: {
      name: 'UGDFarmasi'
    },
    menu: 'UGDFarmasi',
    icon: 'icon-home4',
  },
  {
    title: 'Rekonsiliasi Obat UGD',
    route: {
      name: 'UGDRekonsiliasi'
    },
    menu: 'UGDRekonsiliasi',
    icon: 'icon-stack-text',
  },
  {
    title: 'UGD Rekam Medis',
    route: {name: 'UGDRekamMedis'},
    menu: 'UGDRekamMedis',
    icon: 'icon-home4',
  },
  {
    title: 'UGD UPLA',
    route: {name: 'UGDUPLA'},
    menu: 'UGDUPLA',
    icon: 'icon-home4',
  },
  {
    title: 'UGD Rujukan',
    icon: 'icon-file-text3',
    children: [
      {
        title: 'Rujukan Masuk',
        route: { name: 'UGDRujukanMasuk' },
        menu: 'UGDRujukanMasuk'
      },
      {
        title: 'Rujukan Keluar',
        route: { name: 'UGDRujukanKeluar' },
        menu: 'UGDRujukanKeluar'
      },
    ]
  },
  {
    title: 'Surat Emergency',
    route: {
      name: 'UGDSuratEmergency'
    },
    menu: 'UGDSuratEmergency',
    icon: 'icon-file-text3',
  },
  {
    title: 'Hais UGD',
    route: {
      name: 'UGDHais'
    },
    menu: 'UGDHais',
    icon: 'icon-notification2',
  },
  {
    title: 'Penerbitan Surat',
    route: {name: 'UGDPenerbitanSurat'},
    menu: 'UGDPenerbitanSurat',
    icon: 'icon-bookmarks',
  },
  {
    title: 'Pasien Rawat Inap',
    route: {
      name: 'UGDPasienRanap'
    },
    menu: 'UGDPasienRanap',
    icon: 'icon-notification2',
  },
  {
    title: 'CPPT Dokter Spesialist',
    route: {
      name: 'UGDCPPTDokterSpesialist'
    },
    menu: 'UGDCPPTDokterSpesialist',
    icon: 'icon-list',
  },
  {
    cap: "Rawat Inap",
    relate: [
      //General
      'DashboardRanap',

      // Admisi
      'RanapAdmisiAntrean',
      'InformasiAdmisiAntrean',
      'RanapRencanaRanap',
      'RanapReservasi',
      'RanapPostOpname',
      'RanapRujukan',
      'PenerbitanSurat',

      // DPJP
      'RanapPasienPerawatanDPJP',
      'RanapVerifPasienSelesai',
      'RanapVerifPiutang',

      'RanapPasienSelesai',
      'RanapOperasiDokterOperator',
      'RanapKonsultasi',
      'RanapRaber',
      'RanapAlihRawat',

      // Perawat Ranap
      'RanapPasienBangsal',
      'RanapPasienPerawatan',
      'RanapOperasiPPJP',

      // Dietisien
      'RanapPasienPerawatanGizi',
      'RanapKonsulDietisien',
      'RanapDPDietisien',
      'RanapETDietisien',
      'RanapPMDietisien',

      // UBS
      'RanapOperasiPerawatBedah',

      // Dokter Anestesi
      'RanapOperasiDokterAnestesi',

      // Farmasi
      'RanapRekonsiliasi',
      'RanapFarmasi',
      'RanapFarmasiResepPulang',
      'RanapDPFarmasi',
      'RanapETFarmasi',
      'RanapPasienSelesaiFarmasi',
      'RanapMESO',

      // Laboratorium
      'RanapTindakanLaboratorium',
      'RanapPOCTLab',
      'RanapPA',

      // Radiologi
      'RanapRadiologi',

      // Fisioterapi
      'RanapTindakanFisioterapi',
      'RanapDPFisioterapi',
      'RanapETFisioterapi',

      // MPP
      'RanapMPP',
      'RanapETMPP',

      // OTHER
      'AppRanapJadwalOperasi',
      'AppRanapJadwalOperasiCRUD',
      'RanapCPPTList',
      'RanapLaporanInsiden',
      'RanapHais',
      'RanapRekamMedis',
      'RanapUPLA',
    ]
  },
  {
    title: 'Dashboard',
    route: { name: 'DashboardRanap' },
    menu: 'DashboardRanap',
    icon: 'icon-home4',
  },
  {
    title: 'Admisi',
    icon: 'icon-users',
    children: [
      {
        title: 'Antrean Ranap',
        route: {
          name: 'RanapAdmisiAntrean'
        },
        menu: 'RanapAdmisiAntrean',
        icon: 'icon-list',
      },
      {
        title: 'Antrean Informasi',
        route: {
          name: 'InformasiAdmisiAntrean'
        },
        menu: 'InformasiAdmisiAntrean',
        icon: 'icon-list',
      },
      {
        title: 'Pendaftaran Ranap',
        route: {
          name: 'RanapReservasi'
        },
        menu: 'RanapReservasi',
        icon: 'icon-bookmarks',
      },
      {
        title: 'Rencana Rawat Inap',
        route: {
          name: 'RanapRencanaRanap'
        },
        menu: 'RanapRencanaRanap',
        icon: 'icon-notification2',
      },
      {
        title: 'Post Opname',
        route: {
          name: 'RanapPostOpname'
        },
        menu: 'RanapPostOpname',
        icon: 'icon-file-text3',
      },
      {
        title: 'Ranap Rujukan',
        route: {
          name: 'RanapRujukan'
        },
        menu: 'RanapRujukan',
        icon: 'icon-file-text3',
      },
      {
        title: 'Penerbitan Surat',
        route: {
          name: 'PenerbitanSurat'
        },
        menu: 'PenerbitanSurat',
        icon: 'icon-bookmarks',
      },
    ],
  },
  {
    title: 'DPJP',
    icon: 'icon-users',
    children: [
      {
        title: 'Pasien Perawatan',
        route: {
          name: 'RanapPasienPerawatanDPJP'
        },
        menu: 'RanapPasienPerawatanDPJP',
        icon: 'fa-solid fa-bed-pulse',
      },
      {
        title: 'Pasien Operasi',
        route: {
          name: 'RanapOperasiDokterOperator'
        },
        menu: 'RanapOperasiDokterOperator',
        icon: 'fa-solid fa-bed-pulse',
      },
      {
        title: 'Pasien Konsul',
        route: {
          name: 'RanapKonsultasi'
        },
        menu: 'RanapKonsultasi',
        icon: 'fa-solid fa-bed-pulse',
      },
      {
        title: 'Pasien Raber',
        route: {
          name: 'RanapRaber'
        },
        menu: 'RanapRaber',
        icon: 'fa-solid fa-bed-pulse',
      },
      {
        title: 'Alih Rawat',
        route: {
          name: 'RanapAlihRawat'
        },
        menu: 'RanapAlihRawat',
        icon: 'icon-users',
      },
    ],
  },  
  {
    title: 'Perawat Ranap',
    icon: 'icon-users',
    children: [
      {
        title: 'Pasien Bangsal',
        route: {
          name: 'RanapPasienBangsal'
        },
        menu: 'RanapPasienBangsal',
        icon: 'fa-solid fa-house-medical',
      },
      {
        title: 'Pasien Perawatan',
        route: {
          name: 'RanapPasienPerawatan'
        },
        menu: 'RanapPasienPerawatan',
        icon: 'fa-solid fa-bed-pulse',
      },
      
      {
        title: 'Pasien Operasi',
        route: {
          name: 'RanapOperasiPPJP'
        },
        menu: 'RanapOperasiPPJP',
        icon: 'fa-solid fa-bed-pulse',
      },
    ],
  },
  {
    title: 'Dietisien',
    icon: 'icon-users',
    children: [
      {
        title: 'Pasien Perawatan',
        route: {
          name: 'RanapPasienPerawatanGizi'
        },
        menu: 'RanapPasienPerawatanGizi',
        icon: 'fa-solid fa-bed-pulse',
      },
      {
        title: 'Pemberian Makan',
        icon: 'fa-solid fa-bowl-food',
        route: {
          name: "RanapPMDietisien"
        },
        menu: "RanapPMDietisien"
      },
      {
        title: 'Pasien Konsul Gizi',
        route: {
          name: 'RanapKonsulDietisien'
        },
        menu: 'RanapKonsulDietisien',
        icon: 'fas fa-book-medical',
      },
      {
        title: 'Edukasi Terintegrasi',
        icon: 'fas fa-book-medical',
        route: {
          name: "RanapETDietisien"
        },
        menu: "RanapETDietisien"
      },
      {
        title: 'Discharge Planning',
        icon: 'icon-stack-text',
        route: {
          name: "RanapDPDietisien"
        },
        menu: "RanapDPDietisien"
      },
    ],
  },
  {
    title: 'Perawat UBS',
    icon: 'icon-users',
    children: [
      {
        title: 'Pasien Operasi',
        route: {
          name: 'RanapOperasiPerawatBedah'
        },
        menu: 'RanapOperasiPerawatBedah',
        icon: 'fa-solid fa-bed-pulse',
      },
    ],
  },
  {
    title: 'Dokter Anestesi',
    icon: 'icon-users',
    children: [
      {
        title: 'Pasien Operasi',
        route: {
          name: 'RanapOperasiDokterAnestesi'
        },
        menu: 'RanapOperasiDokterAnestesi',
        icon: 'fa-solid fa-bed-pulse',
      },
    ],
  },
  {
    title: 'Farmasi',
    icon: 'icon-users',
    children: [
      {
        title: 'Rekonsiliasi Obat',
        route: {
          name: 'RanapRekonsiliasi'
        },
        menu: 'RanapRekonsiliasi',
        icon: 'icon-stack-text',
      },
      {
        title: 'Permintaan Resep',
        route: {
          name: 'RanapFarmasi'
        },
        menu: 'RanapFarmasi',
        icon: 'fas fa-prescription-bottle-medical',
      },
      {
        title: 'Permintaan Resep Pulang',
        route: {
          name: 'RanapFarmasiResepPulang'
        },
        menu: 'RanapFarmasiResepPulang',
        icon: 'fas fa-prescription-bottle-medical',
      },
      {
        title: 'MESO',
        icon: 'icon-stack-text',
        route: {
          name: "RanapMESO"
        },
        menu: "RanapMESO"
      },
      {
        title: 'Edukasi Terintegrasi',
        icon: 'fas fa-book-medical',
        route: {
          name: "RanapETFarmasi"
        },
        menu: "RanapETFarmasi"
      },
      {
        title: 'Discharge Planning',
        icon: 'icon-stack-text',
        route: {
          name: "RanapDPFarmasi"
        },
        menu: "RanapDPFarmasi"
      },
      {
        title: 'Pasien Selesai',
        icon: 'fa-solid fa-person-walking-arrow-loop-left',
        route: {
          name: "RanapPasienSelesaiFarmasi"
        },
        menu: "RanapPasienSelesaiFarmasi"
      }
    ],
  },
  {
    title: 'Laboratorium',
    icon: 'icon-users',
    children: [
      {
        title: 'Ranap Laboratorium',
        route: {
          name: 'RanapTindakanLaboratorium'
        },
        menu: 'RanapTindakanLaboratorium',
        icon: 'icon-home4',
      },
      {
        title: 'POCT',
        route: {
          name: 'RanapPOCTLab'
        },
        menu: 'RanapPOCTLab',
        icon: 'icon-stack-text',
      },
      {
        title: 'Ranap Pemeriksaan Jaringan Tubuh',
        route: {
          name: 'RanapPA'
        },
        menu: 'RanapPA',
        icon: 'icon-file-text3',
      },
    ],
  },
  {
    title: 'Ranap Radiologi',
    route: {
      name: 'RanapTindakanRadiologi'
    },
    menu: 'RanapTindakanRadiologi',
    icon: 'icon-home4',
  },
  {
    title: 'Fisioterapi',
    icon: 'icon-users',
    children: [
      {
        title: 'Ranap Fisioterapi',
        route: {
          name: 'RanapTindakanFisioterapi'
        },
        menu: 'RanapTindakanFisioterapi',
        icon: 'icon-home4',
      },
      {
        title: 'Edukasi Terintegrasi',
        icon: 'fas fa-book-medical',
        route: {
          name: "RanapETFisioterapi"
        },
        menu: "RanapETFisioterapi"
      },
      {
        title: 'Discharge Planning',
        icon: 'icon-stack-text',
        route: {
          name: "RanapDPFisioterapi"
        },
        menu: "RanapDPFisioterapi"
      },
    ],
  },
  {
    title: 'MPP',
    icon: 'icon-users',
    children: [
      {
        title: 'MPP Ranap',
        icon: 'icon-home4',
        route: {
          name: "RanapMPP"
        },
        menu: "RanapMPP"
      },
      {
        title: 'Edukasi Terintegrasi',
        icon: 'fas fa-book-medical',
        route: {
          name: "RanapETMPP"
        },
        menu: "RanapETMPP"
      },
    ],
  },
  {
    title: 'Pasien Selesai',
    route: {
      name: 'RanapPasienSelesai'
    },
    menu: 'RanapPasienSelesai',
    icon: 'fa-solid fa-person-walking-arrow-loop-left',
  },
  {
    title: 'Jadwal Operasi',
    route: {
      name: 'AppRanapJadwalOperasi'
    },
    menu: 'AppRanapJadwalOperasi',
    icon: 'icon-calendar',
  },
  {
    title: 'Jadwal Operasi CRUD',
    route: {
      name: 'AppRanapJadwalOperasiCRUD'
    },
    menu: 'AppRanapJadwalOperasiCRUD',
    icon: 'icon-calendar',
  },
  {
    title: 'CPPT',
    route: {
      name: 'RanapCPPTList'
    },
    menu: 'RanapCPPTList',
    icon: 'fa-solid fa-notes-medical',
  },
  {
    title: 'Laporan Insiden Ranap',
    route: {
      name: 'RanapLaporanInsiden'
    },
    menu: 'RanapLaporanInsiden',
    icon: 'icon-file-text3',
  },
  {
    title: 'HAIS Ranap',
    route: {
      name: 'RanapHais'
    },
    menu: 'RanapHais',
    icon: 'icon-notification2',
  },
  {
    title: 'Rekam Medis Rawat Inap',
    route: {
      name: 'RanapRekamMedis'
    },
    menu: 'RanapRekamMedis',
    icon: 'icon-home4',
  },
  {
    title: 'UPLA Rawat Inap',
    route: {
      name: 'RanapUPLA'
    },
    menu: 'RanapUPLA',
    icon: 'icon-home4',
  },
  {
    title: 'Verifikasi Pasien Selesai',
    icon: 'fa-solid fa-person-walking-arrow-loop-left',
    route: {
      name: "RanapVerifPasienSelesai"
    },
    menu: "RanapVerifPasienSelesai"
  },
  {
    title: 'Verifikasi Piutang',
    icon: 'fa-solid fa-person-walking-arrow-loop-left',
    route: {
      name: "RanapVerifPiutang"
    },
    menu: "RanapVerifPiutang"
  },

  {
    cap: 'Billing',
    relate: [
      'RoBilling',
    ]
  }, 

  {
    title: 'Billing',
    route: {
      name: 'RoBilling'
    },
    menu: 'RoBilling',
    icon: 'icon-clipboard',
  },
  {
    cap: 'Kasir',
    relate: [
      'RoKasir',
    ]
  },

  {
    title: 'Kasir',
    route: {
      name: 'RoKasir'
    },
    menu: 'RoKasir',
    icon: 'icon-credit-card',
  },
  {
    cap: "Admin",
    relate: [
      'MTemplateResep',
      'MTemplatePenunjang',
      'RanapKetersediaanKamar',
      'Users',
      'UserLevel',
      'MAgama',
      'MPekerjaan',
      'MPendidikan',
      'MKategoriSDKI',
      'MSubKategoriSDKI',
      'MSDKI',
      'MSLKI',
      'MKriteriaSLKI',
      'MSIKI',
      'MAlatBantuTarik',
      'MTindakan',
      'MCaraPembayaran',
      'MICD9',
      'MICD10',
      'MICD9UPLA',
      'MICD10UPLA',
      'MPoli',
      'MAsalRujukan',
      'MObat',
      'MAlatKesehatan',
      'MAlatInfus',
      'MRadiologi',
      'MZipCode',
      'MDosis',
      'MSubLab',
      'MLab',
      'MInsiden',      
      'MInformConsent',      
      'MRoFakesRujukan',
      'MRoPoliRujukan',
      'MRoDokterRujukan',
      'MHariLibur',
      'MRuangan',
      'MUGDRS',
      'MUGDPuskesmas',

      'MCairan',
      'MDarahBalanceCairan',
      'MRanapImplant',

      'MRanapBangsal',
      'MRanapKelas',
      'MRanapKamar',
      'MRanapBed',      

      'MRanapJenisTindakanOperasi',
      'MRanapTindakanOperasi',
      'MRanapPenyakitMenular',
      'MFlagAktivitasJadwal',
      'FingerPrint',
      'AuditTrail',
    ]
  },
  {
    title: 'Template Resep',
    route: {
      name: 'MTemplateResep'
    },
    menu: 'MTemplateResep',
    icon: 'icon-list',
  },
  {
    title: 'Template Penunjang',
    route: {
      name: 'MTemplatePenunjang'
    },
    menu: 'MTemplatePenunjang',
    icon: 'icon-list',
  },
  {
    title: 'Ketersediaan Kamar',
    route: {
      name: 'RanapKetersediaanKamar'
    },
    menu: 'RanapKetersediaanKamar',
    icon: 'fa-solid fa-house-medical',
  },
  {
    title: 'Data Finger Print',
    route: {
      name: 'FingerPrint'
    },
    menu: 'FingerPrint',
    icon: 'icon-point-up',
  },
  {
    title: 'Master',
    icon: 'icon-list',
    children: [
      {
        title: "Flaging Aktivitas Jadwal",
        route: { name: 'MFlagAktivitasJadwal' },
        menu: 'MFlagAktivitasJadwal'
      },
      {
        title: 'Aturan Pakai',
        route: { name: 'MDosis' },
        menu: 'MDosis'
      },
      {
        title: 'HAIs',
        route: { name: 'MHais' },
        menu: 'MHais'
      },
      {
        title: 'Kesadaran',
        route: { name: 'MKesadaran' },
        menu: 'MKesadaran'
      },
      {
        title: 'Poli',
        route: { name: 'MPoli' },
        menu: 'MPoli'
      },
      {
        title: 'Obat',
        route: { name: 'MObat' },
        menu: 'MObat'
      },
      {
        title: 'Alat Kesehatan',
        route: { name: 'MAlatKesehatan' },
        menu: 'MAlatKesehatan'
      },
      {
        title: 'Alat Infus',
        route: {
          name: 'MAlatInfus'
        },
        menu: 'MAlatInfus'
      },
      {
        title: 'Radiologi',
        route: { name: 'MRadiologi' },
        menu: 'MRadiologi'
      },
      {
        title: 'Alat Bantu Tarik',
        route: { name: 'MAlatBantuTarik' },
        menu: 'MAlatBantuTarik'
      },
      {
        title: 'Tindakan Fisioterapi',
        route: { name: 'MTindakan' },
        menu: 'MTindakan'
      },
      {
        title: 'Cara Pembayaran',
        route: { name: 'MCaraPembayaran' },
        menu: 'MCaraPembayaran'
      },
      {
        title: 'Agama',
        route: { name: 'MAgama' },
        menu: 'MAgama'
      },
      {
        title: 'Pekerjaan',
        route: { name: 'MPekerjaan' },
        menu: 'MPekerjaan'
      },
      {
        title: 'Pendidikan',
        route: { name: 'MPendidikan' },
        menu: 'MPendidikan'
      },
      {
        title: 'SDKI',
        children: [
        {
          title: 'Kategori',
          route: {
            name: 'MKategoriSDKI'
          },
          menu: 'MKategoriSDKI'
        },
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubKategoriSDKI'
          },
          menu: 'MSubKategoriSDKI'
        },
        {
          title: 'Diagnosa',
          route: {
            name: 'MSDKI'
          },
          menu: 'MSDKI'
        }]
      },
      {
        title: 'SLKI',
        children: [
          {
            title: 'Diagnosa',
            route: {
              name: 'MSLKI'
            },
            menu: 'MSLKI'
          },
          {
            title: 'Kriteria Hasil',
            route: {
              name: 'MKriteriaSLKI'
            },
            menu: 'MKriteriaSLKI'
          }
        ]
      },
      {
        title: 'SIKI',
        route: { name: 'MSIKI' },
        menu: 'MSIKI'
      },
      {
        title: 'Diagnosa Gizi',
        route: { name: 'MICDGizi' },
        menu: 'MICDGizi'
      },
      {
        title: 'Intervensi Gizi',
        route: {
          name: 'MICDGiziTindakan'
        },
        menu: 'MICDGiziTindakan'
      },
      {
        title: 'Tujuan Gizi',
        route: {
          name: 'MICDGiziTujuan'
        },
        menu: 'MICDGiziTujuan'
      },
      {
        title: 'Etiologi Gizi',
        route: {
          name: 'MICDGiziEtimologi'
        },
        menu: 'MICDGiziEtimologi'
      },
      {
        title: 'Problem Gizi',
        route: {
          name: 'MICDGiziProblem'
        },
        menu: 'MICDGiziProblem'
      },
      {
        title: 'Sign & Symptom Gizi',
        route: {
          name: 'MICDGiziSign'
        },
        menu: 'MICDGiziSign'
      },
      {
        title: 'Jenis Diit',
        route: {
          name: 'MJenisDiit'
        },
        menu: 'MJenisDiit'
      },
      {
        title: 'Bentuk Makanan',
        route: {
          name: 'MBentukMakanan'
        },
        menu: 'MBentukMakanan'
      },
      {
        title: 'ICD9 UPLA',
        route: { name: 'MICD9UPLA' },
        menu: 'MICD9UPLA'
      },
      {
        title: 'ICD10 UPLA',
        route: { name: 'MICD10UPLA' },
        menu: 'MICD10UPLA'
      },
      {
        title: 'ICD9',
        route: { name: 'MICD9' },
        menu: 'MICD9'
      },
      {
        title: 'ICD10',
        route: { name: 'MICD10' },
        menu: 'MICD10'
      },
      {
        title: 'Insiden',
        route: { name: 'MInsiden' },
        menu: 'MInsiden'
      },
      {
        title: 'Inform Consent',
        route: { name: 'MInformConsent' },
        menu: 'MInformConsent'
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Cairan',
        route: { name: 'MCairan' },
        menu: 'MCairan'
      },
      {
        title: 'Darah Balance Cairan',
        route: { name: 'MDarahBalanceCairan' },
        menu: 'MDarahBalanceCairan'
      },
      {
        title: 'Implant',
        route: { name: 'MRanapImplant' },
        menu: 'MRanapImplant'
      },
      {
        title: 'Penyakit Menular',
        route: {
          name: 'MRanapPenyakitMenular'
        },
        menu: 'MRanapPenyakitMenular'
      },
      {
        title: 'Laboratorium',
        children: [
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubLab'
          },
          menu: 'MSubLab'
        },
        {
          title: 'Tindakan',
          route: {
            name: 'MLab'
          },
          menu: 'MLab'
        }]
      },

      {
        title: 'Operasi',
        children: [
        {
          title: 'Jenis Tindakan Operasi',
          route: {
            name: 'MRanapJenisTindakanOperasi'
          },
          menu: 'MRanapJenisTindakanOperasi'
        },
        {
          title: 'Tindakan Operasi',
          route: {
            name: 'MRanapTindakanOperasi'
          },
          menu: 'MRanapTindakanOperasi'
        }]
      },

      {
        title: 'Data Ruangan Rawat Inap',
        children: [
          {
            title: 'Bangsal',
            route: {
              name: 'MRanapBangsal'
            },
            menu: 'MRanapBangsal'
          },
          {
            title: 'Kelas',
            route: {
              name: 'MRanapKelas'
            },
            menu: 'MRanapKelas'
          },
          {
            title: 'Kamar',
            route: {
              name: 'MRanapKamar'
            },
            menu: 'MRanapKamar'
          },
          {
            title: 'Bed',
            route: {
              name: 'MRanapBed'
            },
            menu: 'MRanapBed'
          },
        ]
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Fakes Rujukan',
        children: [{
            title: 'Nama Fakes',
            route: {
              name: 'MRoFakesRujukan'
            },
            menu: 'MRoFakesRujukan'
          },
          {
            title: 'Poli',
            route: {
              name: 'MRoPoliRujukan'
            },
            menu: 'MRoPoliRujukan'
          },
          {
            title: 'Dokter',
            route: {
              name: 'MRoDokterRujukan'
            },
            menu: 'MRoDokterRujukan'
          },
        ]
      },
      {
        title: 'Suku',
        route: { name: 'MRoSuku' },
        menu: 'MRoSuku'
      },
      {
        title: 'Hari Libur',
        route: {
          name: 'MHariLibur'
        },
        menu: 'MHariLibur'
      },
      {
        title: 'Riwayat Alergi',
        route: { name: 'MRiwayatAlergi' },
        menu: 'MRiwayatAlergi'
      },
      {
        title: 'Ruangan Rawat Inap',
        route: {
          name: 'MRuangan'
        },
        menu: 'MRuangan'
      },
      {
        title: 'Asal Rujukan UGD (RS)',
        route: {
          name: 'MUGDRS'
        },
        menu: 'MUGDRS'
      },
      {
        title: 'Asal Rujukan UGD (Puskesmas)',
        route: {
          name: 'MUGDPuskesmas'
        },
        menu: 'MUGDPuskesmas'
      },
      {
        title: 'Asal Rujukan POLI',
        route: {
          name: 'MAsalRujukan'
        },
        menu: 'MAsalRujukan'
      }
    ],
  },
  {
    title: 'User Management',
    icon: 'icon-users',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'UserLevel'},
        menu: 'UserLevel'
      },
    ],
  },
  {
    title: 'Audit Trail',
    route: {
      name: 'AuditTrail'
    },
    icon: 'icon-check',
    menu: 'AuditTrail'
  },
  {
    cap: 'Antrean',
    relate: [
      'AntreanRoAudio',
      'AntreanRoUnit',
      'AntreanRoGroup',
      'AntreanRoDisplayLayar',
    ]
  },
  {
    title: 'Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Audio Antrean',
        route: { name: 'AntreanRoAudio' },
        menu: 'AntreanRoAudio'
      },
      {
        title: 'Unit Tujuan',
        route: { name: 'AntreanRoUnit' },
        menu: 'AntreanRoUnit'
      },
      {
        title: 'Group Antrian',
        route: { name: 'AntreanRoGroup' },
        menu: 'AntreanRoGroup'
      },
      {
        title: 'Display Layar',
        route: { name: 'AntreanRoDisplayLayar' },
        menu: 'AntreanRoDisplayLayar'
      },
    ]
  },
  {
    cap: 'Report',
    relate: [
      'ReportFisioterapi',
      'RoReportAdmisi',
      'ReportFarmasi',
      'ReportLab',
      'ReportRadiologi',
      'LaporanManagement',
      'UGDReportAdmisi',
    ]
  },
  {
    title: 'Report',
    icon: 'icon-file-text',
    children: [
    {
      title: 'Report Fisioterapi',
      route: {
        name: 'ReportFisioterapi'
      },
      menu: 'ReportFisioterapi'
    },
    {
      title: 'Report Admisi',
      route: {
        name: 'RoReportAdmisi'
      },
      menu: 'RoReportAdmisi',
    },
    {
      title: 'Report Admisi Rajal',
      route: {
        name: 'ReportAdmisiRajal'
      },
      menu: 'ReportAdmisiRajal',
    },
    {
      title: 'Report Admisi UGD',
      route: {
        name: 'ReportAdmisiUGD'
      },
      menu: 'ReportAdmisiUGD',
    },
    {
      title: 'Report Farmasi',
      route: {
        name: 'ReportFarmasi'
      },
      menu: 'ReportFarmasi',
    },
    {
      title: 'Report Laboratorium',
      route: {
        name: 'ReportLab'
      },
      menu: 'ReportLab',
    },
    {
      title: 'Report Radiologi',
      route: {
        name: 'ReportRadiologi'
      },
      menu: 'ReportRadiologi',
    },
    {
      title: 'Laporan Management',
      route: {
        name: 'LaporanManagement'
      },
      menu: 'LaporanManagement',
    },
    {
      title: 'Report UGD',
      route: {
        name: 'UGDReportAdmisi'
      },
      menu: 'UGDReportAdmisi',
    },
    ]
  },
  {
    cap: "BPJS",
    relate: [
      'BPJSPeserta',
      'BPJSReferensi',
      'BPJSMonitoring',
      'BPJSSEP',
      'BPJSPRB',
      'BPJSRencanaKontrol',
      'BPJSDataSEP',
      'BPJSLPK',
      'BPJSLogAntrean',
      'BPJSRefrensiAntrean',
      'RoTaskIDHarian',
      'RoBelumDilayani',
      'BPJSKetersediaanKamar',
    ]
  },
  {
    title: 'BPJS Aplicare',
    icon: 'icon-list',
    children: [{
      title: 'Ketersediaan Kamar',
      route: {
        name: 'BPJSKetersediaanKamar'
      },
      menu: 'BPJSKetersediaanKamar'
    }]
  },
  {
    title: 'BPJS VCLAIM',
    icon: 'icon-list',
    children: [
      {
        title: 'Peserta',
        route: {
          name: 'BPJSPeserta'
        },
        menu: 'BPJSPeserta'
      },
      {
        title: 'Referensi',
        route: {
          name: 'BPJSReferensi'
        },
        menu: 'BPJSReferensi'
      },
      {
        title: 'Monitoring',
        route: {
          name: 'BPJSMonitoring'
        },
        menu: 'BPJSMonitoring'
      },
      {
        title: 'CEK SEP',
        route: {
          name: 'BPJSSEP'
        },
        menu: 'BPJSSEP'
      },
      {
        title: 'Rujukan',
        route: {
          name: 'BPJSRujukan'
        },
        menu: 'BPJSRujukan'
      },
      {
        title: 'PRB',
        route: {
          name: 'BPJSPRB'
        },
        menu: 'BPJSPRB'
      },
      {
        title: 'Rencana Kontrol',
        route: {
          name: 'BPJSRencanaKontrol'
        },
        menu: 'BPJSRencanaKontrol'
      },
      {
        title: 'Data SEP',
        route: {
          name: 'BPJSDataSEP'
        },
        menu: 'BPJSDataSEP'
      },
      {
        title: 'Data LPK',
        route: {
          name: 'BPJSLPK'
        },
        menu: 'BPJSLPK'
      }
    ],
  },
  {
    title: 'BPJS Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Referensi Antrean',
        route: {
          name: 'BPJSRefrensiAntrean'
        },
        menu: 'BPJSRefrensiAntrean'
      }, 
      {
        title: 'Log Antrean',
        route: {
          name: 'BPJSLogAntrean'
        },
        menu: 'BPJSLogAntrean'
      }, 
      {
        title: 'Aktifitas Task ID Harian',
        route: {
          name: 'RoTaskIDHarian'
        },
        menu: 'RoTaskIDHarian'
      }, 
      {
        title: 'Aktivitas Antrean',
        route: {
          name: 'RoBelumDilayani'
        },
        menu: 'RoBelumDilayani'
      },

      
    ],
  },

  {
    cap: "SATU SEHAT",
    relate: [
      'SatuSehatKYCVerification',
      'SatuSehatEncounter',
      'SatuSehatEncounterUGD',
      'SatuSehatEncounterRanap'
    ],
  },
  {
    title: 'KYC',
    icon: 'icon-users',
    route: {
      name: 'SatuSehatKYCVerification'
    },
    menu: 'SatuSehatKYCVerification',
  },
  {
    title: 'Encounter RAJAL',
    icon: 'icon-users',
    route: {
      name: 'SatuSehatEncounter'
    },
    menu: 'SatuSehatEncounter',
  },
  {
    title: 'Encounter UGD',
    icon: 'icon-users',
    route: {
      name: 'SatuSehatEncounterUGD'
    },
    menu: 'SatuSehatEncounterUGD',
  },
  {
    title: 'Encounter Ranap',
    icon: 'icon-users',
    route: {
      name: 'SatuSehatEncounterRanap'
    },
    menu: 'SatuSehatEncounterRanap',
  },
  {
    cap: "Logistik",
    relate: [
      'MLogistikUnit',
      'MLogistikRuangan'
    ],
  },
  {
    title: 'Master',
    icon: 'icon-list',
    children: [
      {
        title: "Unit",
        route: { name: 'MLogistikUnit' },
        menu: 'MLogistikUnit'
      },
      {
        title: "Ruangan",
        route: { name: 'MLogistikRuangan' },
        menu: 'MLogistikRuangan'
      },
      {
        title: "Aset Golongan",
        route: { name: 'MLogistikAsetGolongan' },
        menu: 'MLogistikAsetGolongan'
      },
      {
        title: "Aset Bidang",
        route: { name: 'MLogistikAsetBidang' },
        menu: 'MLogistikAsetBidang'
      },
      {
        title: "Aset Barang",
        route: { name: 'MLogistikAsetBarang' },
        menu: 'MLogistikAsetBarang'
      },
      {
        title: "Gudang",
        route: { name: 'MLogistikGudang' },
        menu: 'MLogistikGudang'
      },
      {
        title: "Kategori",
        route: { name: 'MLogistikKategori' },
        menu: 'MLogistikKategori'
      },
    ]
  },
]

let nav = menu
const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        if((v2.children||[]).length){
          v2.children = _.filter(v2.children, v3 => {
            return menuRole.indexOf(v3.menu) > -1
          })
          if ((v2.children||[]).length) {
            return v2
          }
        }else{
          return menuRole.indexOf(v2.menu) > -1
        }
      })
    }
    return v
  })
}

export default nav