<template>
  <div class="sidebar sidebar-fixed sidebar-light sidebar-main sidebar-expand-xl">
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a href="#" class="sidebar-mobile-main-toggle" @click="toggleSidebarMobile">
        <i class="icon-arrow-left8"></i>
      </a>
      Menu Navigasi
      <a href="#" class="sidebar-mobile-expand" @click="toggleSidebarMobileFs">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->
    
    <!-- User menu -->
    <!--
    <div class="sidebar-user">
			<div class="card-body">
				<div class="media">
					<div class="mr-3">
						<a href="javascript:;"><img :src="uploader(user.avatar)" width="38" height="38" class="rounded-circle" alt=""></a>
					</div>
					<div class="media-body">
						<div class="media-title font-weight-semibold">{{user.fullName}}</div>
						<div class="font-size-xs opacity-50">
							<i class="icon-pin font-size-sm"></i> &nbsp;{{user.level}}
						</div>
					</div>
				</div>
			</div>
		</div>
    -->
		<!-- /user menu -->
  
    <bo-sidebar-menu
      :items="isNavData"
    />
    <!-- End Sidebar navigation -->
    <!-- End Sidebar scroll-->
  </div>
</template>
<script>
import navigation from '@/navigation'
import navigationDetailRanap from '@/navigationDetailRanap'

import BoSidebarMenu from '@/components/backend/BoSidebarMenu.vue'
import GlobalVue from '../../libs/Global.vue'

export default {
extends: GlobalVue,
  components: {
    BoSidebarMenu, 
  },
  mounted() {
    let body = document.querySelector('body')
    if(this.isDesktop){
      body.classList.remove('mini-sidebar')
    } else{
      body.classList.add('mini-sidebar')
    }
    if(document.querySelector(".content-wrapper")){
      document.querySelector(".content-wrapper").addEventListener("click", function(){
        document.querySelector('body').classList.remove('sidebar-mobile-main')
        document.querySelector('.sidebar.sidebar-main').classList.remove('sidebar-fullscreen')
      })
    }
  },
  data() {
    return {
      linkWave: {
        color: "#777",
      },
      navigation,
      navigationDetailRanap
    }
  },
  computed: {   
    isNavData(){
      if(this.$route.meta?.isDetailRanap){
        return this.navigationDetailRanap
      }else{
        return this.navigation
      }
    },
  },
  methods: {
    toggleSidebarMobile() {
      document.querySelector('body').classList.toggle('sidebar-mobile-main')
      document.querySelector('.sidebar.sidebar-main').classList.remove('sidebar-fullscreen')
    },
    toggleSidebarMobileFs() { document.querySelector('.sidebar.sidebar-main').classList.toggle('sidebar-fullscreen') },
  }

}
</script>